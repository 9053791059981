import React, { Component } from "react"
import { Link } from "gatsby"

export default class FAQ extends Component {
  render() {

    const data = this.props

    return (
      <div name={ data.scrollTo } className="faq-wrapper container u-space-2">
        {/* <!-- Title --> */}
        <div className="w-md-80 w-lg-50 text-center mx-auto mb-9">
          <h2 className="jd-h2-titles">{ data.firstTitle }<span className="font-weight-bold"> { data.mainTitle }</span></h2>
          <br />
        </div>
        {/* <!-- End Title --> */}
        <div className="container">
          <div className="row">
            { data.content.map((faq, i) => {
              return (
                <div key={ i } className="col-sm-6 col-lg-4 mb-7 mb-sm-9">
                  {/* <!-- Icon Block --> */}
                  <div className="pr-lg-4">
                    <h5>{ faq.title }</h5>
                    <p>{ faq.text }</p>
                  </div>
                  {/* <!-- End Icon Block --> */}
                </div>
              )
            }) }
          </div>
          <div className="container u-space-0 text-center">
            <Link
              to={data.btnLink}
              className="btn jd-btn-primary--air u-btn-wide rounded-pill"
              style={{ margin: '2rem' }}
            >
              {data.btnText}
            </Link>
          </div>
      </div>
    </div>
    )
  }
}
