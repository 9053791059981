import React, { Component } from "react"

import Typing from "./Typing";

import ImageTop from "../assets/svg/flat-icons/jd-what-is-for-top.inline.svg";
import ImageBottom from "../assets/svg/flat-icons/jd-what-is-for-bottom.inline.svg";

export default class JubarteIsFor extends Component {
  render() {
    const data = this.props

    return (
      <section className="jubarte-is-for-wrapper position-relative">
        <div className="jd-bg-primary text-white text-center mb-9">
          <div name="typingContainer" className="typing-wrapper container u-space-4">
            <h1 className="text-center" style={{ fontWeight: 100 }}>
              {data.firstTitle} <br />
              <Typing content={data.content} />
              {data.lastTitle}
            </h1>
          </div>
        </div>

        <figure className="position-absolute-top-0 z-index-2">
          <ImageTop />
        </figure>

        <figure className="position-absolute-bottom-0 z-index-2">
          <ImageBottom />
        </figure>
      </section>
    )
  }
}
