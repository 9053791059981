import React, { Component } from "react"

export default class HowItWorks extends Component {
  render() {

    const howItWorks = this.props

    return (
      <div name={ howItWorks.scrollTo } className="how-it-works-wrapper container u-space-3 active">
      {/* <!-- Title --> */}
      <div className="w-md-80 w-lg-50 text-center mx-auto mb-9">
        <h2 className="jd-h2-titles"> { howItWorks.firstTitle } <span className="font-weight-bold">{ howItWorks.mainTitle }</span>?</h2>
      </div>
      {/* <!-- End Title --> */}
      <div className="row align-items-lg-center">
        <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
          <ul className="list-unstyled">
            {/* <!-- Info Block --> */}
            { howItWorks.steps.map((step, i) => {
              return (
                <li key={ i } className="u-indicator-steps py-3">
                  <div className="media align-items-center border rounded p-5">
                    <div className="d-flex u-indicator-steps__inner mr-3">
                      <span className="font-weight-medium jd-h2-titles">{ i + 1 }.</span>
                    </div>
                    <div className="media-body">
                      <h3 className="jd-h6-faq mb-0">{ step.title }</h3>
                      <p className="mb-0">{ step.text }</p>
                    </div>
                  </div>
                </li>
              )
            }) }
            {/* <!-- End Info Block --> */}
          </ul>
        </div>

        <div className="col-lg-6 d-none d-lg-block order-lg-1">
          <div className="pr-lg-7">
            {/* <!-- SVG Icon --> */}
            <img src={ howItWorks.image } className="how-it-works-svg" alt="SVG Illustration" />
            {/* <!-- End SVG Icon --> */}
          </div>
        </div>
      </div>
    </div>
    )
  }
}
