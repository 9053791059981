import React, { Component } from "react"
import "lazysizes"

import { Masonry } from "react-masonry-responsive"

export default class Portfolio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gridwidth: 400,
      activeTab: this.props.tabs[0].title,
      portfolioImages: this.props.imageGrid.map((grid, i) => {
        return {
          key: i,
          node: this.prepareImages(grid, true),
        }
      }),
    }
  }

  handleClick = (tab, grid) => {
    this.setState({ activeTab: tab.title })
    let sortData = []

    if (tab.title !== this.props.tabs[0].title) {
      /* eslint-disable */
      grid.map((item, i) => {
        if (item.category) {
          if (typeof item.category === "object") {
            item.category.map((cat, x) => {
              if (cat === tab) {
                return sortData.push({
                  key: item.key,
                  node: this.prepareImages(item),
                })
              } else {
                return false
              }
            })
          } else {
            if (item.category === tab) {
              return sortData.push({
                key: item.key,
                node: this.prepareImages(item),
              })
            }
          }
        } else {
          return false
        }
        this.setState({ portfolioImages: "" })
        setTimeout(() => {
          return this.setState({ portfolioImages: sortData })
        }, 100)
      })
    } else {
      this.setState({ portfolioImages: "" })
      setTimeout(() => {
        this.setState({
          portfolioImages: this.props.imageGrid.map((grid, i) => {
            return {
              key: i,
              node: this.prepareImages(grid),
            }
          }),
        })
      }, 100)
    }
  }

  prepareImages = (item, lazyLoad = false) => {
    if (lazyLoad) {
      return (
        <picture>
          <source data-srcset={item.imageWEBP} type="image/webp" />
          <source data-srcset={item.imageJPG} type="image/jpeg" />
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            data-src={item.imageJPG}
            className="lazyload portfolio-image"
            alt={item.alt}
          />
        </picture>
      )
    } else {
      return (
        <picture>
          <source srcSet={item.imageWEBP} type="image/webp" />
          <source srcSet={item.imageJPG} type="image/jpeg" />
          <img src={item.imageJPG} className="portfolio-image" alt={item.alt} />
        </picture>
      )
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    this.handleClick(e.target.value, this.props.imageGrid)
  }

  componentDidMount() {
    if (window.innerWidth < 991) {
      this.setState({ gridwidth: 350 })
    }
  }

  render() {
    return (
      <div name={this.props.scrollTo} className="portfolio-wrapper container-fluid u-space-3">
        <div className="w-md-80 w-lg-50 text-center mx-auto mb-9">
          <h2 className="jd-h2-titles">
            {" "}
            {this.props.firstTitle} <span className="font-weight-bold">{this.props.mainTitle}</span>
          </h2>
        </div>

        <ul className="list-inline text-center jd-text">
          {this.props.tabs.map((tab, i) => {
            return (
              <li
                key={i}
                className={"list-inline-item jd-text" + (tab.title === this.state.activeTab ? " active" : "")}
                onClick={() => this.handleClick(tab, this.props.imageGrid)}
                aria-label={tab.ariaLabel}
              >
                {tab.title}
              </li>
            )
          })}
        </ul>

        <div className="select-wrapper">
          <select className="select-dropdown" onChange={this.handleChange}>
            {this.props.tabs.map((tab, i) => {
              return (
                <option
                  key={i}
                  className={"list-inline-item jd-text" + (tab.title === this.state.activeTab ? "active" : "")}
                  value={tab.title}
                  aria-label={tab.ariaLabel}
                >
                  {tab.title}
                </option>
              )
            })}
          </select>
        </div>

        <Masonry items={this.state.portfolioImages} minColumnWidth={this.state.gridwidth} />
      </div>
    )
  }
}
