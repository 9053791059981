import React, { Component } from "react"

export default class WhyJubarte extends Component {
  render() {

    const jubarte = this.props

    return (
      <div className="why-jubarte-wrapper container u-space-3 mt-5">
      {/* <!-- Title --> */}
      <div className="w-md-80 w-lg-50 text-center mx-auto mb-9">
        <h2 className="jd-h2-titles"> { jubarte.firstTitle } <span className="font-weight-bold">{ jubarte.mainTitle }</span>?</h2>
      </div>
      {/* <!-- End Title --> */}

      <div className="u-space-3-bottom">
        <div className="row">
          { jubarte.content.map((service, i) => {
            return (
              <div key={ i } className="col-md-6 mb-5">
                <div className={ i % 2 ? 'pl-md-4' : 'pr-md-4'}>
                  <img className="img-fluid" width="30%" src={ service.svg } alt="ícone" />
                  <h3 className="jd-h6-faq">{ service.title }</h3>
                  <p>{ service.text }</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
    )
  }
}
