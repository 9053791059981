import React, { Component } from "react"

import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop';

export default class Typing extends Component {

  render() {
    return (

    <TypistLoop>
      { this.props.content.map(text => {
        return (
          <Typist key={ text }>
            <span className="jd-animated-text">{ text }</span>
            <Typist.Backspace count={ text.length } delay={ 1500 } />
          </Typist>
        )
      }) }
    </TypistLoop>
    )
  }
}
