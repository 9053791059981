import React, { Component } from "react"

import { Link } from "gatsby"
import { Link as Scroll } from "react-scroll"

export default class HeroSection extends Component {
  render() {
    let heroSection = this.props.content

    return (
      <div className="hero-section-wrapper">
        <div className="d-flex align-items-center position-relative u-bg-gradient-v1 u-space-4-top u-space-5-top--md u-space-0--lg min-height-100vh--lg">
          {/* <!-- Content --> */}
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="g-mb-32">
                  <h1 className={"display-4 font-size-48--md-down mb-3 " + heroSection.titleClass}>
                    {heroSection.title}
                  </h1>
                  <h1 className={"display-4 font-size-48--md-down mb-3 " + heroSection.subtitleClass}>
                    {heroSection.subtitle}
                  </h1>
                  <p
                    className="jd-deckcopy-hero mb-3 pl-0 col-lg-11"
                    dangerouslySetInnerHTML={{ __html: heroSection.text }}
                  ></p>
                </div>
                <div className="d-flex jd-hero-cta">
                  <Scroll
                    activeClass="active"
                    to={this.props.scrollTo}
                    className={"btn jd-btn-primary--air jd-btn-wide rounded-pill mt-4 " + this.props.scrollTo}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    {heroSection.btnText}
                  </Scroll>
                  <Link
                    to={"/" + heroSection.locale + "/#"}
                    className="btn jd-btn-primary--air jd-navbar-btn-success jd-btn-wide rounded-pill ml-4 mt-4 d-md-none vrlps-trigger"
                  >
                    {heroSection.loginButtonText}
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 u-space-2-top">
                <img src={heroSection.svg} className="d-none d-lg-block" alt="Illustration of a team" />
              </div>
            </div>
          </div>
          {/* <!-- End Content --> */}

          {/* <!-- Go to Arrow --> */}
          <div className="scroll-to-top-holder d-none d-lg-inline-block position-absolute-bottom-0 bottom-50 text-center">
            <Scroll
              activeClass="active"
              className="typingContainer js-go-to u-go-to-modern jd-go-to-marine"
              to="typingContainer"
              spy={true}
              smooth={true}
              duration={400}
            >
              <span className="fa fa-arrow-down u-go-to-modern__inner"></span>
            </Scroll>
          </div>
          {/* <!-- End Go to Arrow --> */}
        </div>
      </div>
    )
  }
}
